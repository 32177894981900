@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@1,600&family=Nunito:wght@300&family=Roboto+Slab&display=swap');
@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400|Playfair+Display:400,700,400italic|Libre+Baskerville:400,700,400italic|Muli:300,400|Open+Sans:400,300,700|Oswald:400,700|Raleway:400,100,300,700|Montserrat:400,700|Merriweather:400,300,300italic,400italic,700|Bree+Serif|Vollkorn:400italic,400,700|Abril+Fatface|Cardo:400,400italic);
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
 
*{
    /* font-family: "Inter", sans-serif; */
  font-family: 'Lato';
 
    padding: 0px;
    margin: 0px;
    transition:all 0.06s linear;
    
 -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  
 }
 #root{
  display: flex;
  align-items: center;
  background-color: #25273c;
 }
  

 
  
 #myApp{
    scroll-behavior: smooth;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    transition: all 0.2s linear;
    /* background-color: rgb(229, 224, 224); */
 
 }
 
 
.cursor {
  position: fixed;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  z-index:99999;
  pointer-events: none;
  mix-blend-mode:difference;
 
}

 #myApp::-webkit-scrollbar {
  
    width: 0.6rem;
   
}
 #myApp::-webkit-scrollbar-thumb {
    background:  white;
    border-radius: 20%;
     cursor: pointer;
     border: 1px solid rgb(91, 90, 90) ;
     
}
#myApp::-webkit-scrollbar-track {
   
    box-shadow: inset 0 0 5px 1px rgb(91, 90, 90);
    background: rgba(255, 255, 255, 0.559);
 
}

 

#root{
    display: grid;
    height: 100vh;
}
nav{
 
    /* background: linear-gradient(90deg, #24C6DC 10%, #514A9D 90%); */
    background: #25273c;
    z-index: 999;
   
   position: sticky !important;
 
   transform: translateY(-4px);
   
    
  
    
}
 
 
nav.makeSticky{
   
    top: 1px !important;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2); 
     
}


#mrNav.darkMode{
    background-color: #32383e;
    color: white;
}
 
.navbar-toggler.darkMode  {
    color: rgb(198, 198, 198)  !important;
   
    background-color: white !important;

}

#navbarNavDropdown a{
     color: white;
    text-align: center;
}
#navContainer.darkMode a{
    color: white;
}
 
.nav-item{
    border-radius: 5%;
   
  
}
.navbar-brand{
  color: white !important;
}
 

 
 
 
 #author{
    font-size: 14px;
    width: fit-content;
    display: block;
    margin-left: auto;
      
 }
 #theme{
 position:relative;
 left: 30%;
 border-radius: 50%;

 
   
    

  
 }
 #theme:hover{
    background-color:  rgb(222, 154, 66);
 }
 #theme i{
    font-size: 1.3rem;
    color: black;
 }
  
 #theme.darkMode i{
    color: white;
 }
 #logo{
    mix-blend-mode: color-dodge;
    
 }

 .workingList {
  width: min(60rem, 90%);
  margin-inline: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  justify-content: center;
  gap: 2rem;
  list-style: none;
  padding: 0;
 
}

.workingList li {
  max-width: 12rem;
  justify-self: center;
  display: grid;
  grid-template:
    "icon"
    "line"
    "dot"
    "title"
    "descr" 1fr;
  justify-items: center;
  align-items: flex-start;
  text-align: center;
}

.workingList li .icon {
  grid-area: icon;
  width: 6rem;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  color: white;
  font-size: 2.5rem;
  border: 0.4rem solid white;
  border-radius: 50%;
 
  box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.45),
    inset -0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.45);
    overflow: hidden;
  
}

.workingList li::before {
  content: "";
  grid-area: line;
  height: 2rem;
  color: white;
  border-right: 2px dotted currentColor;
 
}

.workingList li::after {
  content: "";
  grid-area: dot;
  width: 1rem;
  aspect-ratio: 1;
  border-radius: 50%;
background:white;
  justify-self: center;
  margin-bottom: 0.5rem;
 
  
}

.workingList li .title {
  grid-area: title;
  margin-block: 0.5rem;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}
.workingList .title ,
.workingList .descr{
    color: white;
    position: relative;
    
}
.workingList .descr{
    transition: 0.1s linear;
    animation: wave 4s   infinite ;
}
@keyframes wave {
  0% {transform: translateY(0);}
  50% {transform: translateY(10px);}
  100%{transform: translateY(0);

  }
}

  

.pulse {
  
    
  height: 100px;
  width: 200px;
  overflow: hidden;
  position:relative;
  transform: translate(-30%,0);
    mix-blend-mode: color-dodge;
   
}
.pulse:after {
  content: "";
  display: block;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200px 100px"><polyline fill="none" stroke-width="3px" stroke="white" points="2.4,58.7 70.8,58.7 76.1,46.2 81.1,58.7 89.9,58.7 93.8,66.5 102.8,22.7 110.6,78.7 115.3,58.7 126.4,58.7 134.4,54.7 142.4,58.7 197.8,58.7 "/></svg>') 0 0 no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-animation: 4s pulse infinite linear;
          animation: 4s pulse infinite linear;
}
.pulse:before {
  content: "";
  background: #444;

  position: absolute;
  z-index: -1;
  left: 2px;
  right: 2px;
  bottom: 0;
  top: 16px;
  margin: auto;
  height: 1px;
}

@-webkit-keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
  }
  10% {
    clip: rect(0, 66.6666666667px, 100px, 0);
  }
  38% {
    clip: rect(0, 133.3333333333px, 100px, 0);
  }
  48% {
    clip: rect(0, 200px, 100px, 0);
  }
  52% {
    clip: rect(0, 200px, 100px, 0);
  }
  62% {
    clip: rect(0, 200px, 100px, 66.6666666667px);
  }
  90% {
    clip: rect(0, 200px, 100px, 133.3333333333px);
  }
  100% {
    clip: rect(0, 200px, 100px, 200px);
  }
}

@keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
  }
  10% {
    clip: rect(0, 66.6666666667px, 100px, 0);
  }
  38% {
    clip: rect(0, 133.3333333333px, 100px, 0);
  }
  48% {
    clip: rect(0, 200px, 100px, 0);
  }
  52% {
    clip: rect(0, 200px, 100px, 0);
  }
  62% {
    clip: rect(0, 200px, 100px, 66.6666666667px);
  }
  90% {
    clip: rect(0, 200px, 100px, 133.3333333333px);
  }
  100% {
    clip: rect(0, 200px, 100px, 200px);
  }
}
 
 

 .statusList {
  padding-left: 50px;
  mix-blend-mode: color-dodge;
  list-style-type: none;
 
}

 .statusList li {
  color: #4F4F4F;
  padding-left: 16px;
  margin-top: 24px;
  position: relative;
  font-size: 20px;
  line-height: 20px;
}
 .statusList li::before {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #ddd;
  position: absolute;
 top: 0;
 bottom: 0;
 margin: auto 0;
  left: -10%;
}
   
 .statusList p{
    opacity: 0.8;
    font-size: 17px;
    color: white;
 }
 .statusList strong {
  color:white;
}

 .statusList.alternating-colors li:nth-child(1):before {
  border-color: #0BAD02;
}
 .statusList.alternating-colors li:nth-child(2):before {
  border-color: #2378D5;
}
 .statusList.alternating-colors li:nth-child(3):before {
  border-color: burlywood;
}
 
@media screen and (min-width:1000px){
    #list-container{
       
        margin-left: 65%;
     }
     #navContainer{
        padding: 0  5%;
        
     }
    
}
.devImg{
    width: 180px;
    height: 180px;
    mix-blend-mode:color-dodge;
}
.devRow{
    background: white;
    	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
   
}
 
.darkGradient{
  background-color: white;
 
 

    
    	/* background: linear-gradient(-45deg,#240b36, #0F2027, #203A43, #2C5364); */
       background: linear-gradient(-45deg,  #4d4855,#444E60, #36454f ,
#343A46);
     

      	/* background: linear-gradient(-45deg, #29140e, #4a1328, #08242e, #082e25); */
     
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
 
}
.myImage{
  float: right;
  mix-blend-mode: multiply;
  position: relative;

}
.aboutMeParas{
  padding: 20px;
}
#exampleModalLabel{
  padding-left: 20px;
}
 .aboutMeParas p{
  line-height: 30px;
  opacity: 0.8;
 }
 .aboutMeParas p a{
  text-decoration: none;
  
 }

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
 

#about{
  
    color:white;
      margin-top: -4px;
    
    
           position: relative;
           overflow: hidden;
           z-index: 10;
 
 
 
 
 
 
   
  

       /* background: linear-gradient(90deg, #24C6DC 10%, #514A9D 90%); */
    
}
  
/* #about::after {
   content: "";
  position: absolute;
   bottom: 0;
   left: 0;
 
  width: 100%;
 height: 100%;
 z-index: -1;
 
  
 background-size:cover;
 
 
 
  background-image: url( ./images/wave-haikei.svg);
  background-repeat: no-repeat;
  z-index: 0;
} */

 
#about h6{
    text-wrap: nowrap;
}
 #textChange{
    text-wrap: nowrap;
 }
#textChange::before{
    content: "";
     animation: dynamicText 24s   2s infinite  ;
   
    color:white

}
#blinkingCursor{
    height: 100%;
    
    background-color: white;
    margin: 0 2px;
    animation: blinkIt 1s infinite;
}
@keyframes blinkIt {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }

    
}
@keyframes dynamicText{
    0%{content:""}0.70%{content:"A"}1.40%{content:"At"}2.10%{content:"Att"}2.80%{content:"Attr"}3.50%{content:"Attra"}4.20%{content:"Attrac"}4.90%{content:"Attract"}5.60%{content:"Attracti"}6.30%{content:"Attractiv"}7.00%{content:"Attractive"}7.70%{content:"Attractive "}8.40%{content:"Attractive L"}9.10%{content:"Attractive La"}9.80%{content:"Attractive Lan"}10.50%{content:"Attractive Land"}11.20%{content:"Attractive Landi"}11.90%{content:"Attractive Landin"}12.60%{content:"Attractive Landing"}13.30%{content:"Attractive Landing "}14.00%{content:"Attractive Landing P"}14.70%{content:"Attractive Landing Pa"}15.40%{content:"Attractive Landing Pag"}16.10%{content:"Attractive Landing Page"}16.80%{content:"Attractive Landing Pages"}

    22%{content: "Attractive Landing Pages";} 23.14%{content:"Attractive Landing Page"}23.29%{content:"Attractive Landing Pag"}23.43%{content:"Attractive Landing Pa"}23.58%{content:"Attractive Landing P"}23.72%{content:"Attractive Landing "}23.87%{content:"Attractive Landing"}24.01%{content:"Attractive Landin"}24.16%{content:"Attractive Landi"}24.30%{content:"Attractive Land"}24.45%{content:"Attractive Lan"}24.59%{content:"Attractive La"}24.74%{content:"Attractive L"}24.88%{content:"Attractive "}25.03%{content:"Attractive"}25.17%{content:"Attractiv"}25.32%{content:"Attracti"}25.46%{content:"Attract"}25.61%{content:"Attrac"}25.75%{content:"Attra"}25.90%{content:"Attr"}26.04%{content:"Att"}26.19%{content:"At"}26.33%{content:"A"}26.48%{content:""}
  
  
  
  29%{content:""}29.60%{content:"I"}30.20%{content:"In"}30.80%{content:"Int"}31.40%{content:"Inte"}32.00%{content:"Inter"}32.60%{content:"Intera"}33.20%{content:"Interac"}33.80%{content:"Interact"}34.40%{content:"Interacti"}35.00%{content:"Interactiv"}35.60%{content:"Interactive"}36.20%{content:"Interactive "}36.80%{content:"Interactive W"}37.40%{content:"Interactive We"}38.00%{content:"Interactive Web"}38.60%{content:"Interactive Web "}39.20%{content:"Interactive Web A"}39.80%{content:"Interactive Web Ap"}40.40%{content:"Interactive Web App"}41.00%{content:"Interactive Web Appl"}41.60%{content:"Interactive Web Appli"}42.20%{content:"Interactive Web Applic"}42.80%{content:"Interactive Web Applica"}43.40%{content:"Interactive Web Applicat"}44.00%{content:"Interactive Web Applicati"}44.60%{content:"Interactive Web Applicatio"}45.20%{content:"Interactive Web Application"}45.80%{content:"Interactive Web Applications"}
  
  49%{content:"Interactive Web Applications"}50.14%{content:"Interactive Web Application"}50.28%{content:"Interactive Web Applicatio"}50.42%{content:"Interactive Web Applicati"}50.56%{content:"Interactive Web Applicat"}50.70%{content:"Interactive Web Applica"}50.84%{content:"Interactive Web Applic"}50.98%{content:"Interactive Web Appli"}51.12%{content:"Interactive Web Appl"}51.26%{content:"Interactive Web App"}51.40%{content:"Interactive Web Ap"}51.54%{content:"Interactive Web A"}51.68%{content:"Interactive Web "}51.82%{content:"Interactive Web"}51.96%{content:"Interactive We"}52.10%{content:"Interactive W"}52.24%{content:"Interactive "}52.38%{content:"Interactive"}52.52%{content:"Interactiv"}52.66%{content:"Interacti"}52.80%{content:"Interact"}52.94%{content:"Interac"}53.08%{content:"Intera"}53.22%{content:"Inter"}53.36%{content:"Inte"}53.50%{content:"Int"}53.64%{content:"In"}53.78%{content:"I"}53.92%{content:""}
  
  56%{content:""}56.61%{content:"W"}57.22%{content:"We"}57.83%{content:"Wel"}58.44%{content:"Well"}59.05%{content:"Well "}59.66%{content:"Well D"}60.27%{content:"Well Do"}60.88%{content:"Well Doc"}61.49%{content:"Well Docu"}62.10%{content:"Well Docum"}62.71%{content:"Well Docume"}63.32%{content:"Well Documen"}63.93%{content:"Well Document"}64.54%{content:"Well Documente"}65.15%{content:"Well Documented"}65.76%{content:"Well Documented "}66.37%{content:"Well Documented W"}66.98%{content:"Well Documented We"}67.59%{content:"Well Documented Web"}68.20%{content:"Well Documented Webs"}68.81%{content:"Well Documented Websi"}69.42%{content:"Well Documented Websit"}70.03%{content:"Well Documented Website"}70.64%{content:"Well Documented Websites"}
  75%{content:"Well Documented Websites"}76.20%{content:"Well Documented Website"}76.40%{content:"Well Documented Websit"}76.60%{content:"Well Documented Websi"}76.80%{content:"Well Documented Webs"}77.00%{content:"Well Documented Web"}77.20%{content:"Well Documented We"}77.40%{content:"Well Documented W"}77.60%{content:"Well Documented "}77.80%{content:"Well Documented"}78.00%{content:"Well Documente"}78.20%{content:"Well Document"}78.40%{content:"Well Documen"}78.60%{content:"Well Docume"}78.80%{content:"Well Docum"}79.00%{content:"Well Docu"}79.20%{content:"Well Doc"}79.40%{content:"Well Do"}79.60%{content:"Well D"}79.80%{content:"Well "}80.00%{content:"Well"}80.20%{content:"Wel"}80.40%{content:"We"}80.60%{content:"W"}80.80%{content:""}
  
  83%{content:""}83.50%{content:"R"}84.00%{content:"Re"}84.50%{content:"Res"}85.00%{content:"Resp"}85.50%{content:"Respo"}86.00%{content:"Respon"}86.50%{content:"Respons"}87.00%{content:"Responsi"}87.50%{content:"Responsiv"}88.00%{content:"Responsive"}88.50%{content:"Responsive "}89.00%{content:"Responsive W"}89.50%{content:"Responsive We"}90.00%{content:"Responsive Web"}90.50%{content:"Responsive Webs"}91.00%{content:"Responsive Websi"}91.50%{content:"Responsive Websit"}92.00%{content:"Responsive Website"}92.50%{content:"Responsive Websites"}
  
  96.5%{content:"Responsive Websites"}97.15%{content:"Responsive Website"}97.30%{content:"Responsive Websit"}97.45%{content:"Responsive Websi"}97.60%{content:"Responsive Webs"}97.75%{content:"Responsive Web"}97.90%{content:"Responsive We"}98.05%{content:"Responsive W"}98.20%{content:"Responsive "}98.35%{content:"Responsive"}98.50%{content:"Responsiv"}98.65%{content:"Responsi"}98.80%{content:"Respons"}98.95%{content:"Respon"}99.10%{content:"Respo"}99.25%{content:"Resp"}99.40%{content:"Res"}99.55%{content:"Re"}99.70%{content:"R"}99.85%{content:""}
  
     
}
#skills{
    text-align: center;  
}

 /* .currentStatus{
    backdrop-filter: blur(5.7px);
-webkit-backdrop-filter: blur(4.7px);
  } */
 .balloons.workingList .icon{
      width: 130px;
      height: 130px;
      padding: 20px;
    }
    .balloons .title{
     
         transform: translateY(-10px);
         animation: titleWave 4s infinite;

    }

  @keyframes titleWave {
    0%{

         transform: translateY(-10px);
    }
    50%{
      transform: translateY(10px);
    }
    100%{
       transform: translateY(-10px);
    }
        
    
  }

        
  .balloonImg{
    mix-blend-mode: color-dodge;
 
  }
     
#skills.darkMode{
    background-color: #32383e;
    color: white;
}
#categories{
  border: 1px solid rgb(222, 154, 66) ;
  border-bottom: 8px solid rgb(222, 154, 66) ;
  transition: 0.3s linear;
  position: relative;
  color: white;
  mix-blend-mode: color-dodge;
 
 
}
#categories.darkMode{
    color: black;
}
#categories ul{
    list-style-type: circle;
}
#categories ul li{
    padding: 0.8rem;
}

#categories .headList{
font-size: 20px;
font-weight: 700;
text-wrap: nowrap;
mix-blend-mode: color-dodge;
}
#categories .subList{
    font-size: 30px;
   
 }
#categories .subList i{
 font-size: 35px;
 color: rgb(222, 154, 66);
}
 
.confidence{
    position: absolute;
    height: 100%;
    
   
    top: 0;
    right: 0;
    display: flex;
     align-items: center;
     

    padding: 0.8rem;
    
   
     
    
}
.textContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    height: 70%;
   
 
 
     
  
}
.lineHighlighter{
    width: 1px;
    height: 100%;
  
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    
    

    
}
.skillLevel{
    height: 2px;
   display: block;
   width: 0;
background-color: rgb(222, 154, 66);
}

 
.percentText{
    font-size: 25px;
    color: rgb(222, 154, 66);
    text-align: end;
    opacity: 0;
    transform: translateY(20px);
}
.showMe{
    opacity: 1;
    transform: translateY(0);
}
#justForStyling{
    background-color: rgb(222, 154, 66);
    height: 10px;
}
 
#projects{
    text-align: center;
   
  

    color: black;
}
#projects.darkMode{
     
    background-color: #32383e;

}
.projectList{
    border-top: 1px solid rgb(222, 154, 66) ;
     
  border-bottom: 8px solid rgb(222, 154, 66);
 
 
  color: white;
}
.projectList img{
  height: 340px;
   
}
.blendMode{
   mix-blend-mode: color-dodge;

}
 
#honed{
    background-color: white;
}
#honed.darkMode{
    background-color: #32383e;
    color: white;
}
 
 
 
 
#box a{
    display: block;
    text-decoration: none;
    color: white;
}
#box p{
    padding:5px;
}
 
#box #tech a{
    color: white;
}
#box #tech{
 
word-break: keep-all;
width: 70%;
height: 20%;
    font-size: 25px;
    font-weight: 700;
    display: none;
    transition: 0.4s linear;
    z-index: 2;
    top: 20%;
    left: 15%;

}
#box:hover #tech{
    display: block;
 
}
 
#box:hover img{
    transition: 0.2s linear;
    
     
        filter: brightness(25%);
   
}

#certifications{
     
   
    text-align: center;
}
#certifications.darkMode{
    background-color: #32383e;
}
#myCerti{
    background-color: white;
    color: black;
}
#myCerti.darkMode{
    background-color: #32383e;
    color: white;
}
#certifications .carousel-inner img{
    width:  900px;
    height: 600px;
}
 #Icon{
 
    border-radius: 50%;
 
    display: grid;
    align-items: center;
    font-size: 1.5rem;
    text-align: center;
       border: 4px solid white;
         box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.45),
    inset -0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.45) ;
    
}
 #Icon i{
      font-size: 2.5rem;
      
     
    }

    .brainContainer{
    width:50vw;
    height:50vw;
    max-height:400px;
    max-width:400px;
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    overflow:hidden;
    cursor:pointer;
    z-index:-1;
}
svg{
    height:100%;
    width:100%;
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
}
path{
	fill:transparent;
}
.brainPath{
	stroke:#00ff00;
	stroke-width:1;
}
.brainCircle{
	stroke:#00FF00;
	fill:#00FF00;
}
.brainRect{
	stroke:#00FF00;
	fill:#00FF00;
}
.brainEllipse{
	stroke:#00FF00;
	fill:#00FF00;
}
.animatePaths{
  animation: goPath 3000ms alternate infinite;
}
.animateCircles{
  animation: goCircle 3000ms alternate infinite;
}
.animateRects{
  animation: goRect 3000ms alternate infinite;
}
@keyframes goPath {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes goRect {
  to {
    opacity:0;
  }
}
@keyframes goCircle {
  0% {
		opacity:0;
  }
  50% {
		opacity:1;
  }
  100% {
		opacity:0;
  }
}
 
#mySkills{
  color: white;
  padding: 50px 0 20px 0;
     mix-blend-mode: color-dodge;
 
 
}

#mySkills.darkMode{
    background-color: #32383e;
}
.dots{
  width: 1px;
  height: 50px;
  border-left:2px dotted white;
 position: relative;
  margin: 0 auto;
}
.dots::before{
  position: absolute;
 transform: translate(-55%,-50%);
    content: "";
  grid-area: dot;
  width: 1rem;
  aspect-ratio: 1;
  border-radius: 50%;
background:white;
  justify-self: center;
  margin-bottom: 0.5rem;
 
}
 
 /* .playerContainer{
 
   width: 100px;
   height: 100px;
   overflow: hidden;
 
   margin: 0 auto;
 
  
   mix-blend-mode: color-dodge;
 
 }
 .lines{
  width: 100px;
  height: 100px;
  position: relative;
  scale: 4;
 
 
 }
  .lines svg{
 position: absolute;
 left: 0 ;
 top: 0;

 }   
  */


#product{
    color:white;
     
    height: 500px;
   mix-blend-mode: color-dodge;
     display: flex;
     align-items: center;
   
}
 
 #disappear{
  z-index: 3;
 
  
  
     transition: all 0.3s ease;
    
 }
#disappear h1{
     font-size: 70px;
    
 
}
#disappear.activate{
    transform: scale(0);
      z-index: -3  ;
  
  
     
        
       
    
}
 

#prod{
    text-align: center;
  
 
    
 
}
 
#line{
    border-bottom: 3px solid  rgb(222, 154, 66);
}
#git{
  
 
 z-index: -3;
scale: 0;
  transition: all 0.3s ease;
 top: 0 !important;
 
}
#git,#disappear{
  position: absolute;
  width: 100%;
  top: auto;
  bottom: auto;
  transform: translate(0,-50%);
 
 
}
#git input,#git textarea{
  color: white;
  background: transparent;
  
}
#git.makeVisible{
   z-index: 3;
scale: 1;
}
#touchbtn{
    background-color: #212529 ;
    color: white;
}
 
#footLinks{
    color:white;
}
.myLinks{
  mix-blend-mode: color-dodge;
}
#designBy{
    border-bottom: 1px solid white;
    color: white
}
#designBy span{
    color:white
}
#footLinks i{
    font-size:1rem;
    width: 30px;
    height: 30px;
color:white;
display: grid;
justify-content: center;
align-items: center;
cursor: pointer;

background-color: #212529;


}
#footLinks i:hover,#touchbtn:hover,#Submitme:hover,#btn-back-to-top:hover{
  background-color: #2d3339;
}
  #academy{
          mix-blend-mode: color-dodge;
    }
#footLinks a{
    text-decoration: none;
}
#btn-back-to-top {
    background-color: #212529;
 position: fixed;
 bottom: 20px;
 right: 20px;
 opacity: 0;
 z-index: -1;
    mix-blend-mode: color-dodge;
    animation:wave 4s infinite;
    transform: translateY(10px);
    transition: all 0.2s linear !important;
} 
#btn-back-to-top i{
  color: white;
} 
#btn-back-to-top.makeVisible{
  transform: translateY(-10px);
  opacity: 1;
   z-index: 99;
}
#btn-back-to-top i{  
  font-size: 1rem;
          
}

.hoverStyle:hover{
    background-color:#d1cfcf;
 
 
    color: black;

   

}
.skills-container{
 
    border-radius:10px;
}
.skills-container:hover{
    background-color: white;
}
.skills-container.darkMode:hover{
    background-color: #4b545c;
}
.skill-type{
    padding-top: 2px;
}
.skills-icons{
    font-size: 2rem;
}
.bar{
    height: 100%;
    display: flex;
    align-items: center;
}
.track{
    
        background-color:rgb(222, 154, 66);
        height: 20px;
}

/* css for ripple animation*/
.lds-ripple {
    
    position: relative;
    height: 50px;
    width: fit-content;
    margin: 0 auto;
   
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.4 , 0.8, 1) infinite;
    
    
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  /* css for ripple animation*/
  @keyframes lds-ripple {
    0% {
      top: 26px;
      left: 26px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 26px;
      left: 26px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 26px;
      left: 26px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 52px;
      height: 52px;
      opacity: 0;
    }
  }
  #quoteContainer{
    /* background-color: rgb(209, 207, 207); */
    color: white;
     
    display: grid;
    align-items: center;

  }
  #quoteContainer i{
    font-size: 1.5rem;
    
  }
  
  #spinner{
    font-size: 1.3rem;
    animation: spinMe 1s   infinite ;
    color: white;
  }
  @keyframes spinMe{
    from{
        transform: rotateZ(0deg);
    }
    to{
        transform: rotateZ(360deg);
    }
  }
  #response{
    
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    text-align: center;
    background-color:  rgb(222, 154, 66);
    display: grid;
    align-items: center;
    transform: scale(0);
    z-index: -1;
    height: 100%;
    width: 100%;
    color: black;

     
   
  }
  #response.showMe{
    opacity: 1;
    transform: scale(1);
    z-index: 3;
  
    transition: 0.3s linear;
  }
  #Submitme{
    
  color: white;
  background-color: #212529;
 
    
  }
#aboutMe{
  
  margin-bottom: 120px;
  
 
}
#aboutMe h1{
  font-size: 70px;
  font-weight: 900;

}
#aboutMe h3{
  font-size: 40px;
  

}
#aboutMe h6{
  margin-top: 70px;
}
 .navbar-toggler{
        filter: invert(1);
      }

.lightSaber{
 mix-blend-mode:color-dodge;
 
  height: 500px;
}
.guideToLight{
  height:500px ;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.moreMe{
  display: flex;
  align-items: center;
 
  
}

.moreMe button{
 margin-left: auto;
 color: white;
 
 
 
}
 
 .meCont{
   
  mix-blend-mode: multiply;
  
 }
 .modal-content{
  background: #f5f5f5 !important;
 }
 /* Preloader animation*/

 .loading-container {
  width: 100%;
  max-width: 520px;
  text-align: center;
  color: #fff;
  position: relative;
  margin: 0 auto;
 
}
.loading-container:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #fff;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  -webkit-animation: movingLine 2.4s infinite ease-in-out;
          animation: movingLine 2.4s infinite ease-in-out;
}

@-webkit-keyframes movingLine {
  0% {
    opacity: 0;
    width: 0;
  }
  33.3%, 66% {
    opacity: 0.8;
    width: 100%;
  }
  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 0;
  }
}

@keyframes movingLine {
  0% {
    opacity: 0;
    width: 0;
  }
  33.3%, 66% {
    opacity: 0.8;
    width: 100%;
  }
  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 0;
  }
}
.loading-text {
  font-size: 5vw;
  line-height: 64px;
  letter-spacing: 10px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-evenly;
 
}
.loading-text span {
  -webkit-animation: moveLetters 2.4s infinite ease-in-out;
          animation: moveLetters 2.4s infinite ease-in-out;
  transform: translatex(0);
  position: relative;
  display: inline-block;
  opacity: 0;
  text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
}

.loading-text span:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.loading-text span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.loading-text span:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.loading-text span:nth-child(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.loading-text span:nth-child(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.loading-text span:nth-child(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.loading-text span:nth-child(7) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

@-webkit-keyframes moveLetters {
  0% {
    transform: translateX(-15vw);
    opacity: 0;
  }
  33.3%, 66% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(15vw);
    opacity: 0;
  }
}

@keyframes moveLetters {
  0% {
    transform: translateX(-15vw);
    opacity: 0;
  }
  33.3%, 66% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(15vw);
    opacity: 0;
  }
}
.socials {
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
}

 
@media screen and (min-width:769px) {

  .projectList {
    border-left: 1px solid rgba(255, 255, 255, 0.325) ;
      border-right: 1px solid rgba(255, 255, 255, 0.325)  ;
  }
  
}
@media screen and (max-width:768px){
    #aboutMe{
        text-align: center;
  
    }
    
    #categories .subList{
        font-size: 12px;
       
     }
     #categories ul li{
        padding: 0;
    }
     #categories .headList{
        font-size: 10px;
     }
     #categories .subList i{
        font-size: 16px;
        
       }
     .percentText{
        font-size: 15px;
     }
    #tech a{
        font-size: 12px;
    }
     
    #theme{
        left: 25%;
        
     }
    #academy{
        font-size: 15px;
        text-align: center;
     
    }
    #academy p{
        margin: 0 auto;
    }
    #footLinks i{
        font-size: 1.2rem;
        width: 35px;
    height: 35px;
    }
    
    #btn-back-to-top{
        bottom: 20px;
        right:20px;
        
     
         
        


    }
    #btn-back-to-top i{
        font-size: 1rem;
      
    }
    #Submitme{
        width: 70% !important;
        font-size:15px ;
    }
    #skills h1{
        font-size: 15px;

    }
    #skill-type h4{
        font-size: 12px;
    }
    #skills-icons{
        font-size: 1rem;
    }
   
    #track{
 
        height:10px;
    }
    #projects h1{
        font-size: 15px;
    }
    #certifications h1{
        font-size: 15px;
    }
    #certifications .carousel-inner img{
        width: 100%;
        height: 200px;
    }
    #Icon{
        width: 50px;
        height: 50px;
        font-size: 0.8rem;
        
          
     
    }
    
    #author{
        font-size: 10px;
     }
     
    #projectDescription  {
        font-size: 12px;
    }
    #projectDescription h4 {
        font-size: 16px;
    }
    #projectDescription a{
        font-size: 12px;

    }
    #list-container{
        margin: 0 auto;
    }
    #logo{
        width: 50px;
        height: 50px;
    }
    .textContainer  span{
        font-size: 8px;
    }
    .devRow {
        text-align: center;
    }
    .devRow>div ul{
        padding: 0;
    }
     .devRow>div>div{
        width: 100%;
        margin: 0.8rem 0;
     }
      .statusList li::before {
        left: -4%;
      }
      .projectList img{
  height: 250px;
}
 .statusList li::before {
  left: 0;
  width: 10px;
  height: 10px;
 }
 .statusList{
  padding: 0;
 }
 #categories .headUL{
  padding-left:  8px;
  list-style-type: none;
  
 }
  #categories .headUL .headList{
    font-size: 16px;
  }
  #categories .subUL{
    padding-left: 15px;
    list-style-type: none;
  }
   #categories .subList{
     font-size: 18px;
  }
  .lightsaber__light {
    position: absolute;
    width: 80%;
    left: -70px !important;
    top: 14px ;
    height: 9px;
}
      
     
}

 