 

* {
  -webkit-tap-highlight-color: transparent; }

 

.tooltip {
 
  z-index: 1000;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 13%;
  width: 100px;
  font-size: 12px;
  text-align: center;
  padding: 10px 10px;
  opacity: 0;
  color: #fff;
  transition: all 1s; }
  @media (min-width: 375px) {
    .tooltip {
      font-size: 14px;
      left: 25%;
      margin-left: -65px; } }
  .tooltip.show {
    opacity: 1;
    transition: all 1s 2s; }
    .tooltip.show:after {
      -webkit-animation: left-right 4s infinite 3.5s;
      animation: left-right 4s infinite 3.5s; }
  .tooltip:after {
    z-index: 2;
    content: '';
    position: absolute;
    top: 50%;
    right: -8px;
    margin-top: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 8px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.8); }
  .tooltip.puff-left {
    transition: all .3s;
    opacity: 0;
    -webkit-animation: puff-left .3s;
    animation: puff-left .3s; }

.artist {
  z-index: 1000;
  position: absolute;
  bottom: 0;
  right: 0; }
.mask {
  z-index: 1;
  position: absolute;
  bottom: 100px;
  width: 500px;
  height: 455px;
  left: -200px;
  
 
  }
  @media (min-width: 375px) {
    .mask {
      left: -100px; } }
  .mask:before {
    z-index: 2;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }
  .mask:after {
    z-index: 2;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 200px 0px 100px 100px black, inset -30px 0px 100px 60px black;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }
  .mask .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat; }
  .mask--on:before {
    -webkit-transform: translate(0, -455px);
    transform: translate(0, -455px); }
  .mask--on:after {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
/*
 * Lightsaber
 */
.lightsaber {
  position: absolute;
  z-index: 100;
  width: 450px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  transition: .3s;
  bottom: 5%;
  left: 50%;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .lightsaber__handle {
    position: relative;
    content: '';
    display: block;
    width: 90px;
    height: 40px;
    cursor: pointer; }
  .lightsaber__light-left, .lightsaber__light-right {
    z-index: -1;
    content: '';
    position: absolute;
    width: 40px;
    height: 6px;
    border-top-right-radius: 100% 4px;
    border-bottom-right-radius: 100% 4px;
    background: linear-gradient(red 0%, #fefefe 14%, #fefefe 47%, #fefefe 90%, red 100%);
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-animation: small-glow 2s infinite;
    animation: small-glow 2s infinite; }
  .lightsaber__light-left {
    right: -29px;
    top: 5px;
    -webkit-transform: rotate(-90deg) scale(0, 1);
    transform: rotate(-90deg) scale(0, 1); }
  .lightsaber__light-right {
    right: -35px;
    top: 32px;
    -webkit-transform: rotate(90deg) scale(0, 1);
    transform: rotate(90deg) scale(0, 1); }
  .lightsaber__light {
    position: absolute;
    width: 80%;
    left: 89px;
    top: 14px;
    height: 9px; }
    .lightsaber__light:before {
      z-index: 1100;
      content: '';
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      border-top-right-radius: 50% 7px;
      border-bottom-right-radius: 50% 7px;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-animation: glow 2s infinite;
      animation: glow 2s infinite; }
    .lightsaber__light:after {
      z-index: 1000;
      content: '';
      visibility: visible;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      border-top-right-radius: 50% 7px;
      border-bottom-right-radius: 50% 7px;
      background: linear-gradient(red 0%, #fefefe 14%, #fefefe 47%, #fefefe 90%, red 100%);
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0; }
  .lightsaber__line {
    position: absolute;
    top: 0;
    left: 20%;
    width: 50%;
    height: 10px;
    border-radius: 80%;
    box-shadow: 0 0 5px red, inset 0 0 5px red;
    border: 1px solid #fefefe;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: 0;
    transform-origin: 0;
    transition: -webkit-transform .2s;
    transition: transform .2s; }
    .lightsaber__line:nth-child(2) {
      top: -1px;
      left: -30px; }
    .lightsaber__line:nth-child(3) {
      top: -1px;
      left: 35%; }
  .lightsaber .lightsaber__ripple {
    opacity: 0; }
  .lightsaber--on .lightsaber__light:after,
  .lightsaber--on .lightsaber__light:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  .lightsaber--on .lightsaber__light-left {
    -webkit-transform: rotate(-90deg) scale(1, 1);
    transform: rotate(-90deg) scale(1, 1); }
  .lightsaber--on .lightsaber__light-right {
    -webkit-transform: rotate(90deg) scale(1, 1);
    transform: rotate(90deg) scale(1, 1); }
  .lightsaber--on .lightsaber__line {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    transition: -webkit-transform .5s .1s;
    transition: transform .5s .1s;
    -webkit-animation: lineMove .5s .5s infinite;
    animation: lineMove .5s .5s infinite; }
  .lightsaber--on .lightsaber__light-right .lightsaber__ripple,
  .lightsaber--on .lightsaber__light-left .lightsaber__ripple {
    top: 0;
    width: 13px; }
    .lightsaber--on .lightsaber__light-right .lightsaber__ripple:nth-child(7),
    .lightsaber--on .lightsaber__light-left .lightsaber__ripple:nth-child(7) {
      left: 20%;
      height: 5px; }
    .lightsaber--on .lightsaber__light-right .lightsaber__ripple:nth-child(8),
    .lightsaber--on .lightsaber__light-left .lightsaber__ripple:nth-child(8) {
      left: 45%;
      height: 5px; }
  .lightsaber--on .lightsaber__light-right .lightsaber__spark,
  .lightsaber--on .lightsaber__light-left .lightsaber__spark {
    z-index: 1200;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 2px;
    opacity: 0;
    background: linear-gradient(#fefefe 0%, #fefefe 47%, #fefefe 100%);
    border-radius: 50%;
    -webkit-transform-origin: 0;
    transform-origin: 0; }
    .lightsaber--on .lightsaber__light-right .lightsaber__spark.up,
    .lightsaber--on .lightsaber__light-left .lightsaber__spark.up {
      -webkit-animation: spark-up 0.9s infinite;
      animation: spark-up 0.9s infinite; }
      .lightsaber--on .lightsaber__light-right .lightsaber__spark.up:nth-child(1),
      .lightsaber--on .lightsaber__light-left .lightsaber__spark.up:nth-child(1) {
        left: 0%;
        top: 30%;
        -webkit-animation-delay: .2s;
        animation-delay: .2s; }
      .lightsaber--on .lightsaber__light-right .lightsaber__spark.up:nth-child(2),
      .lightsaber--on .lightsaber__light-left .lightsaber__spark.up:nth-child(2) {
        left: 6%;
        -webkit-animation-delay: .5s;
        animation-delay: .5s; }
      .lightsaber--on .lightsaber__light-right .lightsaber__spark.up:nth-child(3),
      .lightsaber--on .lightsaber__light-left .lightsaber__spark.up:nth-child(3) {
        left: -3%;
        -webkit-animation-delay: .7s;
        animation-delay: .7s; }
    .lightsaber--on .lightsaber__light-right .lightsaber__spark.down,
    .lightsaber--on .lightsaber__light-left .lightsaber__spark.down {
      -webkit-animation: spark-down 0.9s infinite;
      animation: spark-down 0.9s infinite; }
      .lightsaber--on .lightsaber__light-right .lightsaber__spark.down:nth-child(4),
      .lightsaber--on .lightsaber__light-left .lightsaber__spark.down:nth-child(4) {
        left: 0%;
        top: 30%;
        -webkit-animation-delay: .2s;
        animation-delay: .2s; }
      .lightsaber--on .lightsaber__light-right .lightsaber__spark.down:nth-child(5),
      .lightsaber--on .lightsaber__light-left .lightsaber__spark.down:nth-child(5) {
        left: 0%;
        top: 30%;
        -webkit-animation-delay: .4s;
        animation-delay: .4s; }
      .lightsaber--on .lightsaber__light-right .lightsaber__spark.down:nth-child(6),
      .lightsaber--on .lightsaber__light-left .lightsaber__spark.down:nth-child(6) {
        left: 48%;
        top: 30%;
        -webkit-animation-delay: .6s;
        animation-delay: .6s; }
  .lightsaber--on .sparks .lightsaber__spark {
    z-index: 1200;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 10px;
    height: 2px;
    opacity: 0;
    background: linear-gradient(#fefefe 0%, #fefefe 47%, #fefefe 100%);
    border-radius: 50%;
    -webkit-transform-origin: 0;
    transform-origin: 0; }
    .lightsaber--on .sparks .lightsaber__spark.up {
      -webkit-animation: spark-up 0.9s infinite;
      animation: spark-up 0.9s infinite; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(1) {
        left: 28%;
        -webkit-animation-delay: .2s;
        animation-delay: .2s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(2) {
        left: 36%;
        -webkit-animation-delay: .1s;
        animation-delay: .1s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(3) {
        left: 45%;
        -webkit-animation-delay: .45s;
        animation-delay: .45s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(4) {
        left: 50%;
        top: 43%;
        -webkit-animation-delay: .65s;
        animation-delay: .65s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(5) {
        left: 53%;
        top: 42%;
        -webkit-animation-delay: 1s;
        animation-delay: 1s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(6) {
        left: 60%;
        -webkit-animation-delay: .55s;
        animation-delay: .55s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(7) {
        left: 67%;
        -webkit-animation-delay: .4s;
        animation-delay: .4s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(8) {
        left: 73%;
        top: 42%;
        -webkit-animation-delay: .2s;
        animation-delay: .2s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(9) {
        left: 78%;
        -webkit-animation-delay: 0;
        animation-delay: 0; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(10) {
        left: 84%;
        top: 42%;
        -webkit-animation-delay: .85s;
        animation-delay: .85s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(11) {
        left: 90%;
        -webkit-animation-delay: .35s;
        animation-delay: .35s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(12) {
        left: 95%;
        top: 45%;
        -webkit-animation-delay: .15s;
        animation-delay: .15s; }
      .lightsaber--on .sparks .lightsaber__spark.up:nth-child(13) {
        left: 93%;
        -webkit-animation-delay: .1s;
        animation-delay: .1s; }
    .lightsaber--on .sparks .lightsaber__spark.down {
      -webkit-animation: spark-down 0.9s infinite;
      animation: spark-down 0.9s infinite;
      top: 52%; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(14) {
        left: 93%;
        top: 42%;
        -webkit-animation-delay: .2s;
        animation-delay: .2s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(15) {
        left: 95%;
        top: 42%;
        -webkit-animation-delay: .1s;
        animation-delay: .1s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(16) {
        left: 90%;
        top: 42%;
        -webkit-animation-delay: .45s;
        animation-delay: .45s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(17) {
        left: 84%;
        -webkit-animation-delay: 65s;
        animation-delay: 65s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(18) {
        left: 78%;
        -webkit-animation-delay: 1s;
        animation-delay: 1s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(19) {
        left: 73%;
        -webkit-animation-delay: .55s;
        animation-delay: .55s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(20) {
        left: 67%;
        -webkit-animation-delay: .4s;
        animation-delay: .4s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(21) {
        left: 60%;
        -webkit-animation-delay: .2s;
        animation-delay: .2s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(22) {
        left: 53%;
        -webkit-animation-delay: 0;
        animation-delay: 0; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(23) {
        left: 50%;
        -webkit-animation-delay: .85s;
        animation-delay: .85s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(24) {
        left: 46%;
        -webkit-animation-delay: .6s;
        animation-delay: .6s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(25) {
        left: 35%;
        -webkit-animation-delay: .1s;
        animation-delay: .1s; }
      .lightsaber--on .sparks .lightsaber__spark.down:nth-child(26) {
        left: 28%;
        -webkit-animation-delay: .8s;
        animation-delay: .8s; }
  .lightsaber--on .lightsaber__ripple {
    position: absolute;
    z-index: 1100;
    top: 14px;
    height: 8px;
    width: 40px;
    border-radius: 50%;
    background: linear-gradient(#fefefe 0%, #fefefe 47%, #fefefe 100%);
    opacity: 1;
    transition: opacity .1s .5s;
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
    -webkit-transform-origin: 0;
    transform-origin: 0;
    -webkit-animation: ripple .2s .3s infinite linear;
    animation: ripple .2s .3s infinite linear; }
    .lightsaber--on .lightsaber__ripple:nth-child(1) {
      left: 20%;
      height: 8px;
      -webkit-animation-delay: .5s;
      animation-delay: .5s; }
    .lightsaber--on .lightsaber__ripple:nth-child(2) {
      left: 30%;
      -webkit-animation-delay: .6s;
      animation-delay: .6s; }
    .lightsaber--on .lightsaber__ripple:nth-child(3) {
      left: 40%;
      -webkit-animation-delay: .7s;
      animation-delay: .7s; }
    .lightsaber--on .lightsaber__ripple:nth-child(4) {
      left: 50%;
      -webkit-animation-delay: .8s;
      animation-delay: .8s; }
    .lightsaber--on .lightsaber__ripple:nth-child(5) {
      left: 60%;
      -webkit-animation-delay: .5s;
      animation-delay: .5s; }
    .lightsaber--on .lightsaber__ripple:nth-child(6) {
      left: 67%;
      -webkit-animation-delay: .6s;
      animation-delay: .6s; }
    .lightsaber--on .lightsaber__ripple:nth-child(7) {
      left: 75%;
      height: 7px;
      -webkit-animation-delay: .7s;
      animation-delay: .7s; }
    .lightsaber--on .lightsaber__ripple:nth-child(8) {
      left: 80%;
      height: 7px;
      -webkit-animation-delay: .8s;
      animation-delay: .8s; }

@-webkit-keyframes glow {
  from {
    box-shadow: 0 0 10px rgba(243, 206, 121, 0.5), 0 0 10px rgba(243, 206, 121, 0.5), 0 7px 80px rgba(255, 0, 0, 0.8), 0 -7px 80px rgba(255, 0, 0, 0.8), 0 -20px 100px rgba(255, 0, 0, 0.5), 0 20px 100px rgba(255, 0, 0, 0.5), 80px -20px 100px rgba(255, 0, 0, 0.5), 80px 20px 100px rgba(255, 0, 0, 0.5); }
  50% {
    box-shadow: 0 0 10px rgba(243, 206, 121, 0.5), 0 0 10px rgba(243, 206, 121, 0.5), 0 5px 75px rgba(255, 0, 0, 0.8), 0 -5px 75px rgba(255, 0, 0, 0.8), 0 -18px 95px rgba(255, 0, 0, 0.5), 0 18px 95px rgba(255, 0, 0, 0.5), 80px -20px 95px rgba(255, 0, 0, 0.5), 80px 20px 95px rgba(255, 0, 0, 0.5); }
  to {
    box-shadow: 0 0 10px rgba(243, 206, 121, 0.5), 0 0 10px rgba(243, 206, 121, 0.5), 0 7px 80px rgba(255, 0, 0, 0.8), 0 -7px 80px rgba(255, 0, 0, 0.8), 0 -20px 100px rgba(255, 0, 0, 0.5), 0 20px 100px rgba(255, 0, 0, 0.5), 80px -20px 100px rgba(255, 0, 0, 0.5), 80px 20px 100px rgba(255, 0, 0, 0.5); } }

@keyframes glow {
  from {
    box-shadow: 0 0 10px rgba(243, 206, 121, 0.5), 0 0 10px rgba(243, 206, 121, 0.5), 0 7px 80px rgba(255, 0, 0, 0.8), 0 -7px 80px rgba(255, 0, 0, 0.8), 0 -20px 100px rgba(255, 0, 0, 0.5), 0 20px 100px rgba(255, 0, 0, 0.5), 80px -20px 100px rgba(255, 0, 0, 0.5), 80px 20px 100px rgba(255, 0, 0, 0.5); }
  50% {
    box-shadow: 0 0 10px rgba(243, 206, 121, 0.5), 0 0 10px rgba(243, 206, 121, 0.5), 0 5px 75px rgba(255, 0, 0, 0.8), 0 -5px 75px rgba(255, 0, 0, 0.8), 0 -18px 95px rgba(255, 0, 0, 0.5), 0 18px 95px rgba(255, 0, 0, 0.5), 80px -20px 95px rgba(255, 0, 0, 0.5), 80px 20px 95px rgba(255, 0, 0, 0.5); }
  to {
    box-shadow: 0 0 10px rgba(243, 206, 121, 0.5), 0 0 10px rgba(243, 206, 121, 0.5), 0 7px 80px rgba(255, 0, 0, 0.8), 0 -7px 80px rgba(255, 0, 0, 0.8), 0 -20px 100px rgba(255, 0, 0, 0.5), 0 20px 100px rgba(255, 0, 0, 0.5), 80px -20px 100px rgba(255, 0, 0, 0.5), 80px 20px 100px rgba(255, 0, 0, 0.5); } }

@-webkit-keyframes small-glow {
  from {
    box-shadow: 0 0 5px rgba(243, 206, 121, 0.5), 0 0 5px rgba(243, 206, 121, 0.5), 0 7px 20px rgba(255, 0, 0, 0.6), 0 -7px 20px rgba(255, 0, 0, 0.6), 20px 7px 20px rgba(255, 0, 0, 0.4), 20px -7px 20px rgba(255, 0, 0, 0.4); }
  50% {
    box-shadow: 0 0 5px rgba(243, 206, 121, 0.5), 0 0 5px rgba(243, 206, 121, 0.5), 0 5px 15px rgba(255, 0, 0, 0.6), 0 -5px 15px rgba(255, 0, 0, 0.6), 20px 7px 15px rgba(255, 0, 0, 0.4), 20px -7px 15px rgba(255, 0, 0, 0.4); }
  to {
    box-shadow: 0 0 5px rgba(243, 206, 121, 0.5), 0 0 5px rgba(243, 206, 121, 0.5), 0 7px 20px rgba(255, 0, 0, 0.6), 0 -7px 20px rgba(255, 0, 0, 0.6), 20px 7px 20px rgba(255, 0, 0, 0.4), 20px -7px 20px rgba(255, 0, 0, 0.4); } }

@keyframes small-glow {
  from {
    box-shadow: 0 0 5px rgba(243, 206, 121, 0.5), 0 0 5px rgba(243, 206, 121, 0.5), 0 7px 20px rgba(255, 0, 0, 0.6), 0 -7px 20px rgba(255, 0, 0, 0.6), 20px 7px 20px rgba(255, 0, 0, 0.4), 20px -7px 20px rgba(255, 0, 0, 0.4); }
  50% {
    box-shadow: 0 0 5px rgba(243, 206, 121, 0.5), 0 0 5px rgba(243, 206, 121, 0.5), 0 5px 15px rgba(255, 0, 0, 0.6), 0 -5px 15px rgba(255, 0, 0, 0.6), 20px 7px 15px rgba(255, 0, 0, 0.4), 20px -7px 15px rgba(255, 0, 0, 0.4); }
  to {
    box-shadow: 0 0 5px rgba(243, 206, 121, 0.5), 0 0 5px rgba(243, 206, 121, 0.5), 0 7px 20px rgba(255, 0, 0, 0.6), 0 -7px 20px rgba(255, 0, 0, 0.6), 20px 7px 20px rgba(255, 0, 0, 0.4), 20px -7px 20px rgba(255, 0, 0, 0.4); } }

@-webkit-keyframes spark-up {
  0% {
    opacity: 0; }
  75% {
    opacity: 0; }
  80% {
    opacity: 1;
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0); }
  100% {
    -webkit-transform: translate(20px, -5px) rotate(-18deg);
    transform: translate(20px, -5px) rotate(-18deg);
    opacity: 0; } }

@keyframes spark-up {
  0% {
    opacity: 0; }
  75% {
    opacity: 0; }
  80% {
    opacity: 1;
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0); }
  100% {
    -webkit-transform: translate(20px, -5px) rotate(-18deg);
    transform: translate(20px, -5px) rotate(-18deg);
    opacity: 0; } }

@-webkit-keyframes spark-down {
  0% {
    opacity: 0; }
  75% {
    opacity: 0; }
  80% {
    opacity: 1;
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0); }
  100% {
    -webkit-transform: translate(20px, 2px) rotate(20deg);
    transform: translate(20px, 2px) rotate(20deg);
    opacity: 0; } }

@keyframes spark-down {
  0% {
    opacity: 0; }
  75% {
    opacity: 0; }
  80% {
    opacity: 1;
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0); }
  100% {
    -webkit-transform: translate(20px, 2px) rotate(20deg);
    transform: translate(20px, 2px) rotate(20deg);
    opacity: 0; } }

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    border-radius: 1px;
    -webkit-transform: translate(0, -1px) rotate(0deg);
    transform: translate(0, -1px) rotate(0deg); }
  100% {
    opacity: 1;
    border-radius: 50%;
    -webkit-transform: translate(0, 1px) rotate(0deg);
    transform: translate(0, 1px) rotate(0deg); } }

@keyframes ripple {
  0% {
    opacity: 1;
    border-radius: 1px;
    -webkit-transform: translate(0, -1px) rotate(0deg);
    transform: translate(0, -1px) rotate(0deg); }
  100% {
    opacity: 1;
    border-radius: 50%;
    -webkit-transform: translate(0, 1px) rotate(0deg);
    transform: translate(0, 1px) rotate(0deg); } }

@-webkit-keyframes lineMove {
  0% {
    -webkit-transform: translate(0, -1px);
    transform: translate(0, -1px); }
  100% {
    -webkit-transform: translate(20px, 1px);
    transform: translate(20px, 1px); } }

@keyframes lineMove {
  0% {
    -webkit-transform: translate(0, -1px);
    transform: translate(0, -1px); }
  100% {
    -webkit-transform: translate(20px, 1px);
    transform: translate(20px, 1px); } }

@-webkit-keyframes puff-left {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(-20px, 0);
    transform: translate(-20px, 0); } }

@keyframes puff-left {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(-20px, 0);
    transform: translate(-20px, 0); } }

@-webkit-keyframes left-right {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  50% {
    -webkit-transform: translate(14px, 0);
    transform: translate(14px, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes left-right {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  50% {
    -webkit-transform: translate(14px, 0);
    transform: translate(14px, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }